import React from 'react'
import { Button, Card, CardBody, CardHeader, CardImg, CardSubtitle, CardText, CardTitle, Col, FormGroup, Input, Label } from 'reactstrap';

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: props.question,
      options: props.options,
      currentValue: 0
    };
  }

  onChange(e) {
    this.props.addPoints(e.target.value - this.state.currentValue)
    this.setState(
      Object.assign(
        {},
        this.state,
        { currentValue: e.target.value }
      )
    )
  }

  resetState() {
    this.setState({ currentValue: 0 })
  }

  render() {
    const q = this.props.question
    this.props.registerChildren(this)

    const bRadioList = Object.keys(this.props.options).map(key => {
      const v = this.props.options[key]
      let numSuffix = this.props.idPrefix + key
      return (
        <div>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                id={numSuffix}
                name={this.props.idPrefix}
                value={key}
                key={numSuffix}
                onClick={(e) => this.onChange(e)} />
              {v}
            </Label>
          </FormGroup>
        </div>
      )
    })

    const radioList = Object.keys(this.props.options).map(key => {
      const v = this.props.options[key]
      let numSuffix = this.props.idPrefix + key
      return (
        <div className="radio_input">
          <input
            type="radio"
            id={numSuffix}
            name={this.props.idPrefix}
            value={key}
            key={numSuffix}
            onClick={(e) => this.onChange(e)} />
          <label htmlFor={numSuffix}>{v}</label>
        </div>
      )
    })

    //console.log(this.props.options)
    /*        <div className="question">
              <fieldset id={this.props.idPrefix} key={this.props.idPrefix + 'q'}>
                <legend>{q}</legend>
                {radioList}
              </fieldset>
            </div>
            */

    return (
      <Col className="mx-2 px-0" >
        <Card
          color="light"
          outline
          className='mx-0 px-0 border-0'
          style={{ width: "24rem" }}
        >
          <CardBody className="mx-0 px-0">
            <CardHeader>{q}</CardHeader>
            {bRadioList}
          </CardBody>
        </Card>
      </Col >
    )
  }
}

export default Question
import logo from './logo.svg';
//import './AppHome.css';
import QuestionsList from './QuestionsList';
import Loading from "./components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Row, Col, Container } from 'reactstrap';
import
React,
{
  Fragment,
  useState,
} from 'react';

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const AppHome = () => {
  const [points, setPoints] = useState(0);
  const [classification, setClassification] = useState('');
  const [treatment, setTreatment] = useState('');
  let children = []

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();


  const addPoints = (p) => {
    const newPoints = points + p
    setPoints(newPoints);
    let plan = reClassify(newPoints)
    setClassification(plan.classification)
    setTreatment(plan.treatment)
  }

  /**
   * If Points:
   *  0-3: Healthy
   *  4-5: Gingivitis 4346/4355
   *  6-9: Stage I Initial Periodontisis
   *  9-14: Stage II Moderate Periodontisis
   *  14+: Stage III Severe Periodontisis/Chance of Tooth Loss Stage IV Severe Periodontisis
   *        with risk of Loss of Dentition
   * @param {int} p 
   * @returns 
   */
  const reClassify = (p) => {
    if ((p >= 0) && (p <= 3))
      return {
        classification: 'Healthy',
        treatment: "Prophy"
      }
    else if ((p >= 4) && (p <= 5))
      return {
        classification: 'Gingivitis',
        treatment: 'Full Mouth Debridement'
      }
    else if ((p >= 6) && (p <= 9))
      return {
        classification: 'Stage I Initial Periodontisis',
        treatment: 'Scaling and Root Planning'
      }
    else if ((p >= 9) && (p <= 14))
      return {
        classification: 'Stage II Moderate Periodontisis',
        treatment: 'Scaling and Root Planning'
      }
    else if (p >= 14)
      return {
        classification: 'Stage III Severe Periodontisis. Chance of Tooth Loss Stage IV Severe Periodontisis with risk of Loss of Dentition',
        treatment: 'Scaling and Root Planning'
      }
    else
      return {
        classification: '',
        treatment: ''
      }
  }

  const reset = (e) => {
    setPoints(0);
    setClassification('');
    setTreatment('');

    children.forEach(child => child.resetState())
  }

  const registerChildren = (child) => {
    children.push(child)
  }

  /**
   * 
        <div className="header">
          <a href="/">Home</a>
          <a href="periondotic">Periodontic Classification Guide</a>
          <a href="contact">Contact Us</a>
        </div>
   */

  /*
  <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className='points-label'>Points:</div>
                      </td>
                      <td>
                        <div className='points-value'>{points}</div>
                      </td>
                    </tr>
                    <tr className='classification-row'>
                      <td>
                        <div className='classification-label'>Classification:</div>
                      </td>
                      <td>
                        <div className='classification-value'>{classification}</div>
                      </td>
                    </tr>
                    <tr className='treatment-row'>
                      <td>
                        <div className='treament-label'>Treatment:</div>
                      </td>
                      <td>
                        <div className='treament-value'>{treatment}</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <input type="reset" onClick={reset} name="Reset" />
                      </td>
                    </tr>
                  </tbody>
                </table>
                */
  return (
    <Fragment>
      <div className='stage'>
        <div className="main">
          <h1 style={{
            textAlign: 'center'
          }}>Periodonal Classification Guide</h1>
          <hr />
          <form>
            <QuestionsList
              addPoints={addPoints}
              registerChildren={registerChildren} />
            <div style={{ height: '15rem' }}>&nbsp;</div>
            <Container fluid className='fixed-bottom bg-secondary text-light footer' style={{ height: '10rem' }}>
              <Row className='mt-2'>
                <Col className='col-2'>
                  <b>Points: </b>{points}
                </Col>
                <Col className='col-6'>
                  <b>Classification: </b><p>{classification}</p>
                </Col>
                <Col className='col-2'>
                  <b>Treatment: </b><p>{treatment}</p>
                </Col>
                <Col className='col-1' style={{ marginLeft: '10px' }}>
                  <input type="reset" onClick={reset} name="Reset" />
                </Col>
              </Row>
            </Container>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default withAuthenticationRequired(AppHome, {
  onRedirecting: () => <Loading />,
});
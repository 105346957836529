import { Col, Container, Row } from 'reactstrap';
import Question from './Question';
import
React,
{
  useState,
} from 'react';

const QuestionsList = (props) => {
  const registerChildren = props.registerChildren
  return (
    <Container fluid>
      <Row className='mx-0'>
        <Question
          addPoints={props.addPoints}
          question="Tissue Appearance"
          registerChildren={registerChildren}
          idPrefix='TissueAppearance'
          options={
            {
              0: "0 - Pink Firm",
              1: "1 - Slightly Red",
              2: "2 - Moderate Redness",
              3: "3 - Magenta"
            }
          }
        />
        <Question
          addPoints={props.addPoints}
          question="Bleeding"
          registerChildren={registerChildren}
          idPrefix='Bleeding'
          options={
            {
              0: "0 - No Bleeding",
              1: "1 - Slight Bleeding",
              2: "2 - Moderate Bleeding",
              3: "3 - Profuse Bleeding"
            }
          }
        />
        <Question
          addPoints={props.addPoints}
          question="Calculus Deposits"
          registerChildren={registerChildren}
          idPrefix='CalculusDeposits'
          options={
            {
              0: "0 - Light to None",
              1: "1 - Slight Supra",
              2: "2 - Moderate Supra/Sub",
              3: "3 - Heavy Sub"
            }
          }
        />
        <Question
          addPoints={props.addPoints}
          question="Pocketing"
          registerChildren={registerChildren}
          idPrefix='Pocketing'
          options={
            {
              0: "0 - Under 3mm Pktg",
              1: "1 - 3-4mm Pktg",
              2: "2 - 4-5mm Pktg",
              3: "3 - 5-6mm Pktg",
              4: "6mm+ Pktg"
            }
          }
        />
        <Question
          addPoints={props.addPoints}
          question="Bone Levels"
          registerChildren={registerChildren}
          idPrefix='BoneLevels'
          options={
            {
              0: "0 - No Bone Loss",
              1: "1 - Early",
              3: "3 - Moderate Bone Loss",
              4: "4 - Advanced"
            }
          }
        />
        <Question
          addPoints={props.addPoints}
          question="Mobility"
          registerChildren={registerChildren}
          idPrefix='Mobility'
          options={
            {
              0: "0 - No Mobility",
              1: "1 - Class I Mobility",
              2: "2 - Class II",
              3: "3 - Class III"
            }
          }
        />
        <Question
          addPoints={props.addPoints}
          question="Smoking Habit"
          registerChildren={registerChildren}
          idPrefix='SmokingHabit'
          options={
            {
              0: "0 - Non Smoker",
              1: "1 - Smoker"
            }
          }
        />
        <Question
          addPoints={props.addPoints}
          question="Diabetes"
          registerChildren={registerChildren}
          idPrefix='Diabetes'
          options={
            {
              0: "0 - Non Diabetic",
              1: "1 - Diabetic"
            }
          }
        />
        <Question
          addPoints={props.addPoints}
          question="Patient Management"
          registerChildren={registerChildren}
          idPrefix='PatientManagement'
          options={
            {
              0: "0 - Easy",
              1: "1 - Difficult"
            }
          }
        />
        <Question
          addPoints={props.addPoints}
          question="Stain"
          registerChildren={registerChildren}
          idPrefix='Stain'
          options={
            {
              0: "0 - No/Minor Stain",
              1: "1 - Heavy Stain"
            }
          }
        />
      </Row>
    </Container>
  );
}

export default QuestionsList;